export default [
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/shop/calendar/Calendar.vue'),
    meta: {
      contentClass: 'd-flex',
      contentWidth: 'full',
    },
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('@/views/shop/customers/CustomersList.vue'),
    meta: {
      pageTitle: 'Customers',
      breadcrumb: [
        {
          text: 'Customers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/view/:id',
    name: 'customers-view',
    component: () => import('@/views/shop/customers/CustomersView.vue'),
    meta: {
      pageTitle: 'Customers',
      breadcrumb: [
        {
          text: 'Customers',
          to: { name: 'customers' },
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/shop/register/Register.vue'),
    meta: {
      pageTitle: 'Register',
      breadcrumb: [
        {
          text: 'Register',
          active: true,
        },
      ],
      contentClass: 'd-flex',
      contentWidth: 'full',
    },
  },
]
