export default [
  {
    path: '/settings/general',
    name: 'settings-general',
    component: () => import('@/views/settings/general/GeneralSettings.vue'),
    meta: {
      pageTitle: 'General settings',
      breadcrumb: [
        {
          text: 'General settings',
          active: true,
        },
      ],
      contentClass: 'd-flex',
      contentWidth: 'full',
    },
  },
  {
    path: '/settings/company',
    name: 'settings-company',
    component: () => import('@/views/settings/company/CompanySettings.vue'),
    meta: {
      pageTitle: 'Company settings',
      breadcrumb: [
        {
          text: 'Company settings',
          active: true,
        },
      ],
      contentClass: 'd-flex',
      contentWidth: 'full',
    },
  },
  {
    path: '/settings/calendar',
    name: 'settings-calendar',
    component: () => import('@/views/settings/calendar/CalendarSettings.vue'),
    meta: {
      pageTitle: 'Calendar settings',
      breadcrumb: [
        {
          text: 'Calendar settings',
          active: true,
        },
      ],
      contentClass: 'd-flex',
      contentWidth: 'full',
    },
  },
  {
    path: '/settings/sales',
    name: 'settings-sales',
    component: () => import('@/views/settings/sales/SalesSettings.vue'),
    meta: {
      pageTitle: 'Sales settings',
      breadcrumb: [
        {
          text: 'Sales settings',
          active: true,
        },
      ],
      contentClass: 'd-flex',
      contentWidth: 'full',
    },
  },
]
