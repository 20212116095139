import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import moment from 'moment'
import flatpickr from 'flatpickr'
import { Dutch } from 'flatpickr/dist/l10n/nl'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.filter('formatNumber', number => Intl.NumberFormat('nl', { minimumFractionDigits: 2 }).format(number))
Vue.filter('lowercase', value => value.toLowerCase())

Vue.filter('formatDate', (value, format) => {
  if (value) {
    return moment(String(value)).format(format)
  }
  return null
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

i18n.locale = 'nl'
moment.locale('nl')

flatpickr.localize(Dutch)
