export default [
  {
    path: '/messages',
    name: 'messages',
    component: () => import('@/views/service/messages/Messages.vue'),
    meta: {
      pageTitle: 'Messages',
      breadcrumb: [
        {
          text: 'Messages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('@/views/service/feedback/Feedback.vue'),
    meta: {
      pageTitle: 'Feedback',
      breadcrumb: [
        {
          text: 'Feedback',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/service/reports/Reports.vue'),
    meta: {
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
      ],
    },
  },
]
