export default [
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/website/products/ProductList.vue'),
    meta: {
      pageTitle: 'Products',
      breadcrumb: [
        {
          text: 'Products',
          active: true,
        },
      ],
    },
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('@/views/website/services/ServiceList.vue'),
    meta: {
      pageTitle: 'Services',
      breadcrumb: [
        {
          text: 'Services',
          active: true,
        },
      ],
    },
  },
]
